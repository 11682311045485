import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section, Input, Button, Icon, LinkBox, Link, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { FaFacebook, FaInstagram, FaTwitterSquare, FaGithub, FaYoutube } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index3"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header2>
			<Override slot="button" type="link" href="/index2">
				EBOOK
			</Override>
		</Components.Header2>
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal bold 56px/1.2 --fontFamily-googleLora"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Recent Events
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 280px 0px 280px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				A lot of events recently completed by our Ashram
				<br />
				{"\n\n"}
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="0px 0px 30px 0px"
				grid-template-columns="repeat(2, 1fr)"
				md-flex-direction="column"
				align-items="center"
				flex-direction="row"
				justify-content="space-between"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21:20:03.764Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Rebuilding Of 
Rooftop{"\n\n"}
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Param Sant Satguru Mahatma Raghuvar Dayal Ji Maharaj Chacha Ji Maharaj ke Chat Ka karya Karwate Hue Aap Sabke Sevak
							<br />
							<br />
							Anuj Mohan{"\n\n\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21:23:02.959Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Blood Donation Camp
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Shree Ram Raghuvar Aashram Dwara samadhi mein RaktDaan Ka Aayojan Kiya Gaya Aap Sabke Sevak
							<br />
							<br />
							Anuj Mohan{"\n\n\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12:53:35.549Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Bhandara Karyakram
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Shree Ram Raghuvar Aashram Dwara samadhi mein vishaal Bhandare ka ayojan Kiya Gaya Aap Sabka Sevak
							<br />
							<br />
							Anuj Mohan{"\n\n\n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="0px 0px 30px 0px"
				grid-template-columns="repeat(2, 1fr)"
				md-flex-direction="column"
				align-items="center"
				justify-content="space-between"
				flex-direction="row"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Components.QuarklycommunityKitYouTube showOverlay url="https://www.youtube.com/watch?v=N3EFqbc2RHc" controls={false} font-style={false}>
						<Override slot="YouTube Button" background="--color-light" width="70px" height="70px" />
						<Override slot="YouTube Button Icon" color="--dark" />
					</Components.QuarklycommunityKitYouTube>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Garlic and herb linguine
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							This dish features al dente linguine pasta tossed in a flavorful blend of garlic, herbs, and Parmesan cheese.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Components.QuarklycommunityKitYouTube showOverlay url="https://www.youtube.com/watch?v=N3EFqbc2RHc" controls={false} font-style={false}>
						<Override slot="YouTube Button" background="--color-light" width="70px" height="70px" />
						<Override slot="YouTube Button Icon" color="--dark" />
					</Components.QuarklycommunityKitYouTube>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Garlic and herb linguine
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							This dish features al dente linguine pasta tossed in a flavorful blend of garlic, herbs, and Parmesan cheese.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Components.QuarklycommunityKitYouTube showOverlay url="https://www.youtube.com/watch?v=N3EFqbc2RHc" controls={false} font-style={false}>
						<Override slot="YouTube Button" background="--color-light" width="70px" height="70px" />
						<Override slot="YouTube Button Icon" color="--dark" />
					</Components.QuarklycommunityKitYouTube>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Garlic and herb linguine
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							This dish features al dente linguine pasta tossed in a flavorful blend of garlic, herbs, and Parmesan cheese.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section background="#667e3a" color="--dark" padding="64px 0 64px 0">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box>
						<Text font="--headline2" max-width="500px" margin="10px 0 0 0" color="#ffffff">
							Join Our Spiritual Journey
						</Text>
					</Box>
					<Text
						font="normal 300 12px/1.5 --fontFamily-googleLora"
						color="#ffffff"
						letter-spacing="1px"
						text-transform="uppercase"
						margin="0"
						padding="8px 0px 0px 0px"
					>
						Stay inspired with our updates on yoga, enlightenment, and spiritual awakening.
					</Text>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" lg-width="100%">
					<Box>
						<Formspree endpoint="xeqpgrlv">
							<Box
								gap="16px"
								display="grid"
								flex-direction="row"
								flex-wrap="wrap"
								grid-template-columns="repeat(2, 1fr)"
								grid-gap="16px"
							>
								<Box sm-width="100%" display="flex" flex-direction="column" color="#ffffff">
									<Text font="--base" margin="0 0 4px 0">
										Name
									</Text>
									<Input width="100%" name="name" type="text" />
								</Box>
								<Box sm-width="100%" display="flex" flex-direction="column" color="#ffffff">
									<Text font="--base" margin="0 0 4px 0">
										Email
									</Text>
									<Input width="100%" type="email" name="email" />
								</Box>
								<Box display="flex" flex-direction="column" grid-column="1 / span 2">
									<Text font="--base" margin="0 0 4px 0" color="#ffffff">
										Message
									</Text>
									<Input as="textarea" rows="4" width="100%" name="message" />
								</Box>
								<Box display="flex" flex-direction="column" align-items="flex-start" grid-column="1 / span 2">
									<Button>
										Send
									</Button>
								</Box>
							</Box>
						</Formspree>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Footer-10" md-padding="40px 0 40px 0">
			<Text margin="0px 0px 0px 0px" />
			<Box
				display="flex"
				lg-width="100%"
				flex-direction="row"
				lg-flex-direction="row"
				justify-content="space-between"
				width="100%"
				padding="0 0px 0 0px"
				md-padding="0 0px 30px 0px"
				lg-padding="0 0px 50px 0px"
				sm-flex-direction="column"
				md-flex-direction="column"
				md-display="flex"
				md-grid-gap="52px"
			>
				<Box
					lg-margin="0px 0px 0px 0px"
					width="30%"
					display="flex"
					lg-width="50%"
					sm-width="100%"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					sm-margin="0px 0px 0px 0px"
				>
					<Section>
						<Override
							slot="SectionContent"
							width="50% content-box"
							align-items="flex-start"
							display="flex"
							flex-direction="row"
							justify-content="flex-start"
							flex-wrap="no-wrap"
						/>
						<Image
							src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11:00:58.770Z"
							display="block"
							width="120px"
							height="85px"
							padding="0px 0px 0px 0px"
							srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text
							margin="0px 0px 0px 0px"
							font="bold 20px --fontFamily-googleLora"
							text-align="center"
							padding="19px 0px 0px 0px"
							color="rgb(80 101 39)"
						>
							SHREE RAM RAGHUVAR ASHRAM
							<br />
						</Text>
					</Section>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						grid-template-columns="repeat(5, 1fr)"
						grid-gap="16px 24px"
						md-align-self="flex-start"
						align-self="flex-start"
						padding="0px 0px 0px 35px"
						font="23px sans-serif"
						height="30px"
						align-items="center"
						flex-direction="column"
						justify-content="space-between"
						flex-wrap="wrap"
						grid-row-gap="16px"
					>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaFacebook}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaInstagram}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaTwitterSquare}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaGithub}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaYoutube}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaYoutube}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					grid-template-columns="repeat(4, 1fr)"
					grid-gap="36px 34px"
					lg-align-items="start"
					md-grid-template-columns="repeat(4, 1fr)"
					md-grid-gap="36px 34px"
					sm-grid-gap="36px 34px"
					sm-grid-template-columns="repeat(2, 1fr)"
					align-items="flex-start"
					flex-direction="row"
					justify-content="flex-start"
					flex-wrap="wrap"
					align-content="flex-end"
				>
					<Box
						align-items="flex-start"
						lg-align-items="flex-start"
						justify-content="space-between"
						display="flex"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						align-content="stretch"
						grid-gap="8px 0"
						lg-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Contact
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							+91-8795-742-208
						</Link>
					</Box>
					<Box
						align-items="center"
						lg-align-items="flex-start"
						justify-content="space-between"
						display="flex"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						align-content="stretch"
						grid-gap="8px 0"
						lg-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Address
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							8/223, Raghuvar Bhawan,
							<br />
							Arya Nagar , Kanpur - 208007
						</Link>
					</Box>
					<Box
						align-items="center"
						lg-align-items="flex-start"
						justify-content="space-between"
						display="flex"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="no-wrap"
						align-content="start"
						grid-gap="8px 0"
						lg-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Email
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							abc@123.com
						</Link>
					</Box>
					<Box
						align-items="center"
						lg-align-items="flex-start"
						justify-content="space-between"
						display="flex"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						align-content="stretch"
						grid-gap="8px 0"
						lg-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Donate
						</Text>
						<Link
							margin="0px 0 0px 0"
							display="flex"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							UPI: 
+91-8795-742-208{"\n\n"}
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							display="flex"
						>
							A/c No: 123456789
							<br />
							IFSC: UPI112233
						</Link>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 0px 0px">
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				/>
				©   2024 Developed by Complete IT Solution
			</Text>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"670e4a9e1b94b1002431b396"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});